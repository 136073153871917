import { usePage } from '@inertiajs/react';
import { Box, Container, Grid, Typography } from '@mui/material';
import ButtonLink from '../../../components/ButtonLink';

export default function HelpLinks() {
  const {
    props: { water_heater_image: waterHeaterImage, expert_advisor_image: expertAdvisorImage },
  } = usePage();

  return (
    <Container maxWidth="xl" sx={{ mb: { xs: 5, md: 10 } }}>
      <Box sx={{ width: '100%', height: '1px', backgroundColor: 'gray.200', mb: 7 }} />
      <Grid container spacing={{ xs: 2, md: 6 }}>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          order={2}
          mb={{ xs: 2, md: 0 }}
        >
          <Box display="flex" flexDirection="column" maxWidth={{ xs: 'none', md: 500 }}>
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Need an Emergency Water Heater Replacement?
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              We're ready to help. Instead of a standard replacement, we'll help you upgrade to an
              energy-efficient heat pump water heater. Our team ensures a quick, hassle-free
              installation.
            </Typography>
            <ButtonLink
              external
              href="http://svcleanenergy.org/water-heater"
              sx={{ alignSelf: 'flex-start', width: { xs: '100%', md: 'auto' } }}
            >
              Learn More
            </ButtonLink>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={1} mb={{ xs: 2, md: 0 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={waterHeaterImage}
              alt="Workers replacing a water heater"
              sx={{ width: '100%', maxHeight: { xs: 250, md: 480 }, objectFit: 'cover' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 3, md: 4 }}>
          <Box width="100%" height="100%" display="flex" alignItems="center">
            <Box
              component="img"
              src={expertAdvisorImage}
              alt="Advisor talking on the phone"
              sx={{ width: '100%', maxHeight: { xs: 250, md: 480 }, objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center" order={{ xs: 4, md: 3 }}>
          <Box display="flex" flexDirection="column" maxWidth={{ xs: 'none', md: 500 }}>
            <Typography variant="h1" fontSize="1.875rem" fontWeight={600} mb={2}>
              Speak with an Expert Advisor — We're Here to Help
            </Typography>
            <Typography mb={{ xs: 2, md: 4 }}>
              Not sure where to start? Our expert advisors are here to help you navigate rebates,
              find contractors, and create a personalized plan — for free!
            </Typography>
            <ButtonLink
              external
              href="https://goelectric.svcleanenergy.org/"
              sx={{ alignSelf: 'flex-start', width: { xs: '100%', md: 'auto' } }}
            >
              Go Electric Advisor
            </ButtonLink>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
